:root{
  --primaryColor: #003453;
  --secondaryColor: white;

}

.App {
  text-align: center;
}

.ReportHeader{
  color: white;
  background-color: var(--primaryColor);
  position: fixed;
  top: 0px;
  left: 1%;
  width: 98%;
  z-index: 20;
  display: flex;
  flex-flow: column;
  padding: 14px;
  margin: .5rem 0 1rem 0;
}

.ReportContent{
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;

}

.ReportHeader span{
  font-size: 2rem;
}

.LogoutBtn{
  align-self: flex-end;
  color: white !important;
}

.LogoutBtn.hideBtn{
 visibility: hidden;
}

.ReportContainer{
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 76vh;
  margin: 205px 1% 0px;
  padding: 20px;
  color: white;
  align-items: center;
  background-color: rgba(150, 150, 150,0.2);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ReportContainer::-webkit-scrollbar {
  display: none;
}

.material-tooltip{
  display: none;
}

@media (min-width:500px){
  .ReportHeader span{
    font-size: 3rem;
  }
  
  .material-tooltip{
    display: block;
  }
}

