body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Josefin Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ToastSuccess{
  background-color: #4CAF50;
}

.ToastFailure{
  background-color: #F44336;
}

.btn:hover,.btn-floating:hover,.btn:focus,.btn-floating:focus{
  background-color:#003453  !important;
}

.btn,.btn-floating{
  background-color: #003453 !important;
}

.ShadowBox{
  border-radius: 15px;
  box-shadow:  0px 2px 10px black;
}