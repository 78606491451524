.DashBoard{
    color: black;
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.ToastSuccess{
    background-color: #4CAF50;
}

.ToastFailure{
    background-color: #F44336;
}

.TableContainer{
    width: 100%;
    /* overflow: auto; */
}

.TableContainer tbody::-webkit-scrollbar,.FormDiv::-webkit-scrollbar {
    display: none;
  }

.TableContainer td,.TableContainer th{
    min-width: 18.5vw;
    max-width: 10vw;
    overflow-wrap: break-word;
}

.TableContainer tbody{
  display:block;
  overflow:auto;
  height:50vh;
  width:100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.TableContainer thead tr{
    display: block;
}

.TableContainer tbody tr:focus{
    background-color:#003453 !important;
    color: white;
    outline: none;
}

.TableContainer tbody tr:hover{
    background-color:#00838f     !important;
    color: white;
}

.TableContainer tbody button.ctrlBtn{  
    background-color:white !important;
}

.TableContainer tbody button.ctrlBtn i{
    color:#003453 !important;
}

.ControlPanel{
    display: flex;
    flex-flow: column;
    color: white;
    padding: 8px;
    position: fixed;
    top: 190px;
    left: 0px;
    z-index: 20;
}

.ControlBar{
    display: flex;
    flex-flow: column;
    transition: width 0.2s linear,height 0.2s linear;
}

.MenuClosed{
    height: 0px !important;
    width: 0px !important;
}

.ctrlBtn{
    box-shadow: -2px 2px 3px black;
    margin:12px 0px;
}

.ctrlBtn:disabled{
    background-color: darkgray !important;
}

.FormBtn{
    box-shadow: -2px 2px 3px black;
    margin:20px;
}

.RemoveBtn{
    position: absolute;
    right: 1vw;
    text-transform: none;
    font-weight: bold;
    color:#F44336;
}

.FormHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.FormDiv{
    overflow: auto;
    z-index: 10;
    position: absolute;
    border-radius: 15px;
    box-shadow: -3px 4px 3px black;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    margin: 0;
    transition: transform 0.5s;
    transform: translateX(120vw);
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.FormOpen{
    transform: translateX(0);
}

.collapsible-header{
    align-items: center;
    gap: 20px;
}

.collapsible-header::before{
    content: '\002B';
    font-size: 2rem;
    transition: transform 0.2s;
}

li.active .collapsible-header::before{
    transform: rotateZ(45deg);
}

#delete_modal{
    border-radius: 10px;
    overflow: hidden;
}

#delete_modal .modal-footer{
    display: flex;
    justify-content: space-evenly;
}

#delete_modal .btn{
    margin: 0px;
    box-shadow: -2px 2px 3px black;
}

@media (min-width:993px){
    .ControlPanel{
        flex-flow: row;
        width: 50%;
        margin-left: 2%;
        justify-content: left;
        gap: 10%;
    }

    .ControlBar{
        flex-flow: row;
        width: 100%;
        margin-left: 2%;
        justify-content: space-between;
        gap: 10%;
    }

    #delete_modal{
        font-size: 1.5rem;
        width: 40%;
    }
}